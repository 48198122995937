<template>
  <div class="card mx-9">
    <div class="card-header">
      <h3 class="card-title">{{ $t("privacy") }}</h3>
    </div>
    <div class="card-body">
      <div class="fs-5 fw-bold text-gray-600" v-if="$i18n.locale=='hk'">
        <h5>適用範圍</h5>
        以下的隱私權保護政策，適用於您在本活動網站活動時，所涉及的個人資料之收集、運用與保護。

        <h5 class="mt-5">資料蒐集</h5>
        根據本活動網站所提供的不同服務，可能向網友蒐集下列個人基本資料：
        會員資料：當您開始參予本網站之活動時，我們會請您同意我們存取您於facebook上註冊之個人資料：包括姓名、電子信箱等個人資訊。
        一般瀏覽： 本活動網站會保留網友在上網瀏覽或查詢時，伺服器自行產生的相關記錄(LOG)，包括連線設備IP位址、使用時間、使用的瀏覽器、瀏覽及點選資料記錄等，以歸納使用者瀏覽器在本活動網站內部所瀏覽的網頁及瀏覽時間，俾據以提昇本活動網站的服務品質。


        <h5 class="mt-5">網友資料的分享、公開及運用方式</h5>
        本活動網站不會任意出售、交換、或出租任何您的個人資料給其他團體或個人。只有在以下狀況，本活動網站會在「隱私權保護政策」原則之下，運用您的個人資料。
        統計與分析：本活動網站根據使用者帳號資料、投票結果或伺服器日誌文件，進行統計分析與整理，做為本活動票選內容的結果，不會對各別使用者進行分析，亦不會提供特定對象個別資料之分析報告。
        司法單位因公眾安全，要求本活動網站公開特定個人資料時，本活動網站將視司法單位合法正式的程序，以及對本資訊網所有使用者安全考量下做可能必要之配合。除有關法律 裁判、政府審查、司法調查、犯罪預防或就非法活動採取行動、就懷疑詐騙或因事件涉及威脅到任何人的人身安全、以及違法之行為上有需要外，所有資料均屬保密。


        <h5 class="mt-5">關於個人應盡之自我保護措施</h5>
        請妥善保管會員帳號、密碼及任何個人資料，不要將任何個人資料，尤其是密碼提供給任何人。使用本活動網站所提供之各項服務之後，請在離開本資訊網之前，務必登出(Log-out)會員帳號，若您與他人共享電腦或使用公共電腦(如圖書館、學校電腦教室或網咖等)，切記要關閉瀏覽器視窗，以防止他人讀取您的個人資料、信件或進入所屬機關管理區。

        <h5 class="mt-5">隱私權保護政策修訂</h5>
        本活動網站會不定時修訂本項政策，以符合最新之隱私權保護規範。當我們在使用個人資料的規定做較大幅度修改時，我們會在網頁上張貼告示，通知您相關修訂事項。

        <h5 class="mt-5">隱私權保護政策政策諮詢</h5>
        如果您對於本活動網站的隱私權保護政策或對於本機構收集、運用網友個人資料有意見或問題，請直接
        <router-link :to="{name: 'contact'}">聯絡我們</router-link>

        <h5 class="mt-5">請求數據刪除</h5>
        如果您想請求刪除數據，請直接<router-link :to="{name: 'contact'}">聯絡我們</router-link>

        <h5 class="mt-5">退款條款及須知</h5>
        本公司商業登記名稱為 "WON" <br>
        參加比賽並繳費即同意我們的條款，非本公司責任的退款申請，恕不予受理。<br>
        貴客如有任何的索償，本公司只接受退回相關款項為上限，貴客任何的經濟損失，本公司恕不負責。<br>
        除非本公司與貴客另行同意其他退款安排，退款會以電子方式退回相關貴客。
        
      </div>
      <div class="fs-5 fw-bold text-gray-600" v-if="$i18n.locale=='en'">
        <h5>Scope</h5>
        The following privacy protection policy applies to the collection, use and protection of personal data involved in your activities on this website.

        <h5 class="mt-5">Data Collection</h5>
        According to the different services provided by this activity website, the following basic personal information may be collected from netizens:
        Member information: When you start to participate in the activities of this website, we will ask you to agree to our access to your personal information registered on facebook: including your name, email address and other personal information.
        General browsing: This activity website will keep relevant records (LOG) generated by the server when netizens browse or inquire online, including the IP address of the connected device, usage time, browser used, browsing and clicking data records, etc. , in order to summarize the webpages and browsing time that the user's browser browses in this event website, so as to improve the service quality of this event website.

        <h5 class="mt-5">How to share, disclose and use netizen data</h5>
        This event website will not arbitrarily sell, exchange, or rent any of your personal data to other groups or individuals. Only in the following cases, this event website will use your personal data under the principle of "Privacy Protection Policy".
        Statistics and Analysis: This event website conducts statistical analysis and sorting based on user account information, voting results or server log files as the results of the voting content of this event. Analysis report of individual data of a specific object.
        When the judicial unit requests this activity website to disclose specific personal information due to public safety, this activity website will take the legal and formal procedures of the judicial unit and make possible necessary cooperation for the safety of all users of this information website. All information is kept confidential unless necessary for legal adjudication, government review, judicial investigation, crime prevention or taking action against illegal activities, suspected fraud or incidents involving threats to the personal safety of any person, and illegal conduct.
        
        <h5 class="mt-5">About personal self-protection measures</h5>
        Please keep the member account, password and any personal information properly, and do not provide any personal information, especially the password, to anyone. After using the services provided by this event website, please be sure to log out (Log-out) your member account before leaving this information website. If you share a computer with others or use a public computer (such as a library, school computer classroom or Internet cafes, etc.), remember to close the browser window to prevent others from reading your personal data, letters or entering the management area of your agency.

        <h5 class="mt-5">Privacy Policy Revised</h5>
        This event website will revise this policy from time to time to comply with the latest privacy protection regulations. When we make major revisions to the regulations on the use of personal data, we will post a notice on the webpage to notify you of the relevant revisions.

        <h5 class="mt-5">Privacy Policy Policy Consultation</h5>
        If you have any comments or questions about the privacy protection policy of this activity website or the collection and use of personal data of netizens by this organization, please <router-link :to="{name: 'contact'}">contact us</router-link> directly.

        <h5 class="mt-5">Request Data Deletion</h5>
        If you want to request for data deletion, please <router-link :to="{name: 'contact'}">contact us</router-link> directly.

        <h5 class="mt-5">Refund Policy</h5>
        The registered business name of our company is "WON".  <br>
        By applying in the competition and making payment, you agree to our terms. Refund requests that are not the responsibility of our company will not be accepted.<br>
        If you have any claims, our company only accepts refunds up to the relevant amount. Our company is not responsible for any financial losses you may incur. <br>
        Unless otherwise agreed between our company and the customer, refunds will be electronically returned to the customer.
        
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
export default {
  name: "contactPage",
};
</script>
